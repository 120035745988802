import React from 'react'

import { Link } from 'gatsby'
import Layout from '~/components/layout'
import SEO from '~/components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO seo={{ title: '404 - Page non trouvée' }} />
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="text-center">
        <h1 className="mb-2">404 - Page non trouvée</h1>
        <Link to="/">Retour à l'accueil</Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
